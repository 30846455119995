<template>
    <section class="container">
        <div class="columns is-multiline">
            <div class="column">
                <product-card :link="{name:'getQuote',query:{type:'AT'}}" :more-info-link="{name:'annualTrip'}">
                    <template v-slot:image>
                        <router-link :to="{name:'annualTrip'}">
                            <picture>
                                <source srcset="../../../assets/images/carousel/at.webp" type="image/webp">
                                <source srcset="../../../assets/images/carousel/at.jpg" type="image/jpeg">
                                <img src="../../../assets/images/carousel/at.webp" alt="Annual Multi Trip Travel Insurance" loading="lazy" />
                            </picture>
                        </router-link>
                    </template>
                    <template v-slot:card-content>
                        <div class="product-icon">
                            <router-link :to="{name:'annualTrip'}">
                                <picture>
                                    <source srcset="../../../assets/images/icons/amt_icon_orange.webp" type="image/webp">
                                    <source srcset="../../../assets/images/icons/amt_icon_orange.png" type="image/png">
                                    <img src="../../../assets/images/icons/amt_icon_orange.webp" alt="Annual Multi Trip Travel Insurance" loading="lazy" />
                                </picture>
                            </router-link>
                        </div>
                        <router-link :to="{name:'annualTrip'}" tag="h4" class="product-title text-center text-c2g-blue">Annual Multi Trip Travel <br> Insurance</router-link>
                        <div class="card-text">
                            <p>From <strong>£24.05*</strong></p>
                            <p>COVID-19 COVER AVAILABLE</p>
                        </div>
                    </template>
                </product-card>
            </div>
            <div class="column">
                <product-card :link="{name:'getQuote',query:{type:'ST'}}" :more-info-link="{name:'singleTrip'}">
                    <template v-slot:image>
                        <router-link :to="{name:'singleTrip'}">
                            <picture>
                                <source srcset="../../../assets/images/carousel/st.webp" type="image/webp">
                                <source srcset="../../../assets/images/carousel/st.jpg" type="image/jpeg">
                                <img src="../../../assets/images/carousel/st.webp" alt="Single Trip Travel Insurance" loading="lazy" />
                            </picture>
                        </router-link>
                    </template>
                    <template v-slot:card-content>
                        <div class="product-icon">
                            <router-link :to="{name:'singleTrip'}">
                                <picture>
                                    <source srcset="../../../assets/images/icons/st_icon_orange.webp" type="image/webp">
                                    <source srcset="../../../assets/images/icons/st_icon_orange.png" type="image/png">
                                    <img src="../../../assets/images/icons/st_icon_orange.webp" alt="Single Trip Travel Insurance" loading="lazy" />
                                </picture>
                            </router-link>
                        </div>

                        <router-link :to="{name:'singleTrip'}" tag="h4" class="product-title text-center text-c2g-blue">Single Trip Travel <br> Insurance</router-link>

                        <div class="card-text">
                            <p>From <strong>£3.57*</strong></p>
                            <p>COVID-19 COVER AVAILABLE</p>
                        </div>
                    </template>
                </product-card>
            </div>
            <div class="column">
                <product-card :link="{name:'getQuote',query:{type:'CR-ST',end:'CR'}}" :more-info-link="{name:'cruiseCover'}">
                    <template v-slot:image>
                        <router-link :to="{name:'cruiseCover'}">
                            <picture>
                                <source srcset="../../../assets/images/carousel/cr.webp" type="image/webp">
                                <source srcset="../../../assets/images/carousel/cr.jpg" type="image/jpeg">
                                <img src="../../../assets/images/carousel/cr.webp" alt="Cruise Cover" loading="lazy" />
                            </picture>
                        </router-link>
                    </template>
                    <template v-slot:card-content>
                        <div class="product-icon">
                            <router-link :to="{name:'cruiseCover'}">
                                <picture>
                                    <source srcset="../../../assets/images/icons/cr_icon_orange.webp" type="image/webp">
                                    <source srcset="../../../assets/images/icons/cr_icon_orange.png" type="image/png">
                                    <img src="../../../assets/images/icons/cr_icon_orange.webp" alt="Cruise Cover" loading="lazy" />
                                </picture>
                            </router-link>
                        </div>
                        <router-link :to="{name:'cruiseCover'}" tag="h4" class="product-title text-center text-c2g-blue">Cruise Travel <br> Insurance</router-link>
                        <div class="card-text">
                            <p>From <strong>£15.80*</strong></p>
                            <p>COVID-19 COVER AVAILABLE</p>
                        </div>
                    </template>
                </product-card>
            </div>
            <div class="column">
                <product-card :link="{name:'getQuote',query:{type:'ST'}}" :more-info-link="{name:'winterSportsCover'}">
                    <template v-slot:image>
                        <router-link :to="{name:'winterSportsCover'}">
                            <picture>
                                <source srcset="../../../assets/images/carousel/ws.webp" type="image/webp">
                                <source srcset="../../../assets/images/carousel/ws.jpg" type="image/jpeg">
                                <img src="../../../assets/images/carousel/ws.webp" alt="Winter Sports Travel Insurance" loading="lazy" />
                            </picture>
                        </router-link>
                    </template>
                    <template v-slot:card-content>
                        <div class="product-icon">
                            <router-link :to="{name:'winterSportsCover'}">
                                <picture>
                                    <source srcset="../../../assets/images/icons/ski_icon_orange.webp" type="image/webp">
                                    <source srcset="../../../assets/images/icons/ski_icon_orange.png" type="image/jpeg">
                                    <img src="../../../assets/images/icons/ski_icon_orange.webp" alt="Winter Sports Travel Insurance" loading="lazy" />
                                </picture>
                            </router-link>
                        </div>
                        <router-link :to="{name:'winterSportsCover'}" tag="h4" class="product-title text-center text-c2g-blue">Winter Sports Travel <br> Insurance</router-link>
                        <div class="card-text">
                            <p>From <strong>£11.00*</strong></p>
                            <p>COVID-19 COVER AVAILABLE</p>
                        </div>
                    </template>
                </product-card>
            </div>
        </div>
    </section>
</template>

<script type="text/javascript">
    import ProductCard from "./ProductCard";

    export default {
        name: "ProductList",
        components: {
            ProductCard
        }
    }
</script>

<style lang="scss" scoped>
    .card-text {
        margin: 10px 30px;
        height: 80px;
        text-align: center;
    }
    .product-icon {
        position: relative;
        left: 51%;
        transform: translateX(-50%);
        height: 0;
        overflow: visible;
        bottom: 100px;
        width: 300px;
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
        }
    }
    .product-title {
        margin-top: 0;
        margin-bottom: 0;
        z-index: 1;
        position: relative;
        line-height: 1.3;
    }
    img {
        cursor: pointer;
    }

    /deep/ {
        .VueCarousel-dot-container {
            margin-top: 0 !important;
        }
        .VueCarousel-dot-pagination {
        }

        // Hide navigation on smaller screens
        @media screen and (max-width: $small_tablet_width) {
            .fa-angle-right {
                display: none;
            }
            .fa-angle-left {
                display: none;
            }
        }
    }
</style>